import { CardType } from './card-deck';

export enum GameTypes {
  HumanVsComputer = 'hvc',
  HumanVsComputerComputerComputer = 'hvccc',
}

interface PlayerType {
  privateId: string;
  publicId: string;
  hand: CardType[];
  playedCards: CardType[];
  type: 'human' | 'computer';
}

interface OwnPlayerType {
  id: string;
  hand: CardType[];
  playedCards: CardType[];
  type: 'human' | 'computer';
}

interface OpponentType {
  id: string;
  handSize: number;
  playedCards: CardType[];
  type: 'human' | 'computer';
}

export interface ClientGameDataType {
  id: string;
  state: string;
  deckSize: number;
  discardPile: string[] | [];
  player: OwnPlayerType;
  opponents: OpponentType[];
}

//   startGame: function (gameId: string): ClientGameDataType {
//     const game = serverGameEngine.startGame(gameId);
//     const privateId = game.players[0].privateId;
//     return serverGameEngine.sanitizeGameState(game, privateId);
//   },
// };
