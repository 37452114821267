import { Link } from 'react-router-dom';
import { useState, MouseEvent } from 'react';
import { GameTypes } from '../../../pages/play/components/game-server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, fa2, fa4 } from '@fortawesome/free-solid-svg-icons';

export const PlayMenu = function () {
  const [isOpen, setIsOpen] = useState(false);

  let menuHideTimeout: NodeJS.Timeout;

  const hideMenu = function (): void {
    setIsOpen(false);
  };

  const handleMenuEvent = function (event: MouseEvent): void {
    event.preventDefault();

    const elements = document.querySelectorAll(':hover');
    const found = Array.from(elements).find(
      (node) => node.id === 'play-button-wrapper'
    );

    if (found) {
      setIsOpen(true);
      clearTimeout(menuHideTimeout);
    } else {
      menuHideTimeout = setTimeout(hideMenu, 500);
    }
  };

  return (
    <div
      id="play-button-wrapper"
      className="relative"
      onMouseOver={handleMenuEvent}
      onMouseOut={handleMenuEvent}
    >
      <button
        type="button"
        className="text-gray-500 px-4 py-2 group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        aria-expanded="false"
      >
        <span>Play</span>
        <svg
          className="text-gray-400 ml-2 h-5 w-5 group-hover:text-gray-500"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {isOpen && (
        <div className="absolute z-10 -ml-4 mt-3 w-screen max-w-md transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
          <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
              <Link
                to={`/play?gt=${GameTypes.HumanVsComputer}`}
                className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
              >
                <FontAwesomeIcon
                  icon={fa2}
                  className="text-cyan-500 text-2xl pr-1"
                />
                <FontAwesomeIcon
                  icon={faUser}
                  className="text-cyan-500 text-2xl"
                />
                <div className="ml-4 text-left">
                  <p className="text-base font-medium text-gray-900">
                    2 Player Game
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    Human vs. Computer Players
                  </p>
                </div>
              </Link>
              <Link
                to={`/play?gt=${GameTypes.HumanVsComputerComputerComputer}`}
                className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
              >
                <FontAwesomeIcon
                  icon={fa4}
                  className="text-cyan-500 text-2xl pr-1"
                />
                <FontAwesomeIcon
                  icon={faUser}
                  className="text-cyan-500 text-2xl"
                />
                <div className="ml-4 text-left">
                  <p className="text-base font-medium text-gray-900">
                    4 Player Game
                  </p>
                  <p className="mt-1 text-sm text-gray-500">
                    Human vs. 3 Computer Players
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
