import { NavLink } from './nav-link';

export const Footer = function () {
  // text-gray-500 hover:text-gray-900
  return (
    <footer className="flex items-center mt-2 border-t-2 border-gray-100">
      <div className="flex justify-between gap-20 w-full max-w-5xl mt-5 mx-auto">
        <div className="basis-1/3">
          <NavLink to="/" className="text-base font-medium ">
            Play Gin Rummy
          </NavLink>
        </div>
        <div className="basis-1/3"></div>
        <div className="basis-1/3"></div>
      </div>
    </footer>
  );
};
