import { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from './button';

interface LoginButtonProps {
  kind?: 'primary' | 'secondary';
}
export const LoginButton: FC<LoginButtonProps> = ({ kind = 'primary' }) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button kind={kind} onClickHandler={() => loginWithRedirect()}>
      Log In
    </Button>
  );
};
