import { useRef, useState, FC, useEffect } from 'react';
import { GameTypes, ClientGameDataType } from './game-server';
import clientGameEngine from './client-game-engine';
import { useCreateGame } from '../hooks/use-create-game';
import type { Socket } from 'socket.io-client';

const getPixelRatio = function (context: CanvasRenderingContext2D) {
  return (devicePixelRatio = window.devicePixelRatio || 1);
};

const scaleCanvas = function (
  canvas: HTMLCanvasElement,
  context: CanvasRenderingContext2D
) {
  const width = canvas.offsetWidth;
  const height = canvas.offsetHeight;
  console.log('Offset', width, height);

  // Todo: Fix this
  canvas.style.width = width + 'px';
  canvas.style.height = height + 'px';

  const ratio = window.devicePixelRatio;

  canvas.width = width * ratio;
  canvas.height = height * ratio;

  context.scale(ratio, ratio);
};

interface GameCanvasProps {
  gameType: GameTypes;
  socketConnection: Socket;
}

export const GameCanvas: FC<GameCanvasProps> = function ({
  gameType,
  socketConnection,
}) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { gameData, loading, error } =
    useCreateGame<ClientGameDataType>(gameType);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }
    const ratio = getPixelRatio(context);

    scaleCanvas(canvas, context);

    console.log('PixelRatio', ratio);
  }, [canvasRef]);

  useEffect(() => {
    console.log('game', gameData);

    if (!gameData) {
      return;
    }

    if (!canvasRef.current) {
      return;
    }
    const context = canvasRef.current.getContext('2d');
    if (!context) {
      return;
    }
    clientGameEngine.drawDeck(context, gameData.deckSize);
    clientGameEngine.drawDiscardPile(context, gameData.discardPile);
    clientGameEngine.drawHand(context, gameData.player.hand);
    clientGameEngine.drawOpponentsHands(context, gameData.opponents);
  }, [gameData]);

  function startGame() {
    if (gameData && gameData.state === 'new') {
      // setGame(clientGameEngine.startGame(game.id));// Todo: Fix this
    }
  }

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  if (!gameData) {
    return <p>Error: Game data didn't load</p>;
  }

  return (
    <div className="bg-[#497E57] h-[500px]">
      <canvas ref={canvasRef} className="w-full h-full" />
      <div>Player id: {gameData.player.id}</div>
      <div>Player hand: {gameData.player.hand.length}</div>
      <div>Player played cards: {gameData.player.playedCards.length}</div>
      <div>Player type: {gameData.player.type}</div>
      <div>Opponents: {gameData.opponents.length}</div>
      <button onClick={startGame}>Start</button>
    </div>
  );
};
