import { GameTypes } from './game-server';

const clientGameEngine = {
  cardSize: {
    width: 50,
    height: 70,
    margin: 5,
  },
  startGame: function (gameId: string) {
    // TODO emit signal to server to start game
  },
  drawDeck: function (context: CanvasRenderingContext2D, numCards: number) {
    const x = context.canvas.width / 4;
    const y = context.canvas.height / 4;

    drawCanvasCard('', '', x, y, context);
    context.font = '12px Helvetica';
    context.textAlign = 'center';
    context.textBaseline = 'middle';

    context.beginPath();
    context.arc(x, y, 10, 0, Math.PI * 2);
    context.fillStyle = 'rgba(50, 50, 50, 0.5)';
    context.fill();
    context.closePath();

    context.fillStyle = '#FFF';
    context.fillText(numCards.toString(), x, y);
  },
  drawDiscardPile: function (context: CanvasRenderingContext2D, cards: any) {
    const x =
      context.canvas.width / 4 +
      clientGameEngine.cardSize.width +
      clientGameEngine.cardSize.margin;
    const y = context.canvas.height / 4;
    console.log('Discard Pile', cards);
    cards.forEach((card: any) => {
      drawCanvasCard(card.suit, card.rank, x, y, context);
    });
  },
  drawHand: function (context: CanvasRenderingContext2D, cards: any) {
    console.log('Canvas Width', context.canvas.width / 2);

    const handWidth =
      cards.length * clientGameEngine.cardSize.width +
      Math.max(cards.length - 1, 0) * clientGameEngine.cardSize.margin;
    const x = context.canvas.width / 4 - handWidth / 2;
    const y = context.canvas.height / 2;
    const halfCardWidth = clientGameEngine.cardSize.width / 2;

    cards.forEach((card: any, index: number) => {
      drawCanvasCard(
        card.suit,
        card.rank,
        x +
          index * clientGameEngine.cardSize.width +
          clientGameEngine.cardSize.margin * index +
          halfCardWidth,
        y -
          clientGameEngine.cardSize.height / 2 -
          clientGameEngine.cardSize.margin,
        context
      );
    });

    context.beginPath();
    context.rect(
      context.canvas.width / 4 - halfCardWidth,
      0,
      1,
      context.canvas.height / 2
    );
    context.fill();
    context.closePath();
  },
  drawOpponentsHands: function (
    context: CanvasRenderingContext2D,
    opponents: any
  ) {
    const width =
      opponents.length * clientGameEngine.cardSize.width +
      Math.max(opponents.length - 1, 0) * clientGameEngine.cardSize.margin;
    const x =
      context.canvas.width / 4 -
      width / 2 -
      clientGameEngine.cardSize.width / 2;
    const y =
      clientGameEngine.cardSize.height / 2 + clientGameEngine.cardSize.margin;
    opponents.forEach((opponent: any, index: number) => {
      for (let i = 0; i < opponent.handSize; ++i) {
        drawCanvasCard(
          '',
          '',
          x +
            index * clientGameEngine.cardSize.width +
            clientGameEngine.cardSize.margin * index,
          y,
          context
        );
      }
    });
  },
};

const drawCanvasCard = function (
  suit: string,
  rank: string,
  x: number,
  y: number,
  context: CanvasRenderingContext2D
) {
  const cardCenterX = x - clientGameEngine.cardSize.width / 2;
  const cardCenterY = y - clientGameEngine.cardSize.height / 2;

  if (suit === '') {
    context.fillStyle = 'rgb(200, 0, 0)';
  } else {
    context.fillStyle = 'rgb(200, 200, 200)';
  }
  context.fillRect(
    cardCenterX,
    cardCenterY,
    clientGameEngine.cardSize.width,
    clientGameEngine.cardSize.height
  );
  context.strokeStyle = '#000';
  context.stroke();
  if (suit !== '') {
    context.fillStyle = '#000';
    context.fillText(`${suit} - ${rank}`, x, y);
  }
};

export default clientGameEngine;
