import { useEffect, useState } from 'react';
import { ClientGameDataType, GameTypes } from '../components/game-server';
import { API_BASE_URL } from '../../../constants';

export const useCreateGame = function <T>(gameType: GameTypes) {
  const [gameData, setGameData] = useState<ClientGameDataType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const url = `${API_BASE_URL}/game/${gameType}`;

  useEffect(() => {
    const fetchNewGame = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        const newGameData = jsonData.data as ClientGameDataType;
        setGameData(newGameData);
      } catch (err: any) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchNewGame();
  }, []);

  return { gameData, loading, error };
};
