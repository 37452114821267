import { FC } from 'react';
import { Page } from '../../components/page';
import { GameTypes } from './components/game-server';
import { GameCanvas } from './components/game-canvas';
import type { Socket } from 'socket.io-client';

export const playURLConstructor = function (gameType: GameTypes): string {
  return `/play?gt=${gameType}`;
};

const playURLParser = function (): GameTypes {
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const gameType = params.get('gt');

  if (gameType && Object.values(GameTypes).includes(gameType as GameTypes)) {
    return gameType as GameTypes;
  } else {
    return GameTypes.HumanVsComputer;
  }
};

interface PlayProps {
  socketConnection: Socket;
}

export const Play: FC<PlayProps> = function ({ socketConnection }) {
  const gameType = playURLParser();

  return (
    <Page>
      <GameCanvas gameType={gameType} socketConnection={socketConnection} />
    </Page>
  );
};
