import { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { LoginButton } from '../../components/login-button';

interface ImmutableAccountDataProps {
  label: string;
  value: string | boolean;
}
const ImmutableAccountData: FC<ImmutableAccountDataProps> = function ({
  label,
  value,
}) {
  return (
    <div>
      <h2>{label}</h2>
      <p className="lead text-muted">{value}</p>
    </div>
  );
};

export const Account = function () {
  const { user, isAuthenticated, isLoading } = useAuth0();

  console.log('user', user);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return (
      <div>
        <div>Not authenticated</div>
        <LoginButton />
      </div>
    );
  }

  if (!user) {
    return <div>No user</div>;
  }

  return (
    <div className="mb-5">
      <h2>Account</h2>
      <div className="">
        <div>
          <img
            src={user.picture}
            alt="Profile Picture"
            className="rounded-circle img-fluid mb-3 mb-md-0"
          />
        </div>
        <ImmutableAccountData label="Name" value={user.name || ''} />
        <ImmutableAccountData label="Email" value={user.email || ''} />
        <ImmutableAccountData
          label="Email Verified"
          // convert boolean to string for value prop
          value={user.email_verified ? 'Yes' : 'No'}
        />
      </div>
      <div>
        <code>{JSON.stringify(user, null, 2)}</code>
      </div>
    </div>
  );
};
