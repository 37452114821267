import { NumberOfGames } from '../play/components/number-of-games';
import { Page } from '../../components/page';
import { Button } from '../../components/button';
import { NavLink } from '../../components/nav-link';
import { GameTypes } from '../play/components/game-server';
import { playURLConstructor } from '../play/play';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, fa2, fa4 } from '@fortawesome/free-solid-svg-icons';

const EntryButton = function () {
  return (
    <a href="/play">
      <button>Get started</button>
    </a>
  );
};

export const Home = function () {
  return (
    <Page>
      <div className="Home">
        <h2 className="my-14 text-xl text-center">
          Welcome to Play Gin Rummy I/O
        </h2>
        <p className="my-10">
          {/* cspell:disable */}
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Saepe
          excepturi, ducimus consectetur sed voluptates tempore, dolorem rem qui
          repudiandae sequi quasi error minus aliquam aspernatur a. Iusto labore
          necessitatibus quam, amet, ipsum quae rem deserunt molestiae aliquam
          hic laborum laboriosam aliquid saepe fuga facere laudantium dolor
          consequatur quos quas soluta earum corporis! Perspiciatis ea iste
          dolor beatae dicta voluptatem excepturi doloremque officiis animi
          ullam, sit voluptate perferendis nostrum impedit, quod, corrupti enim
          rem aliquid autem hic mollitia. Nisi minima amet ipsam incidunt facere
          hic quasi aliquid minus veritatis deleniti officiis eos cupiditate
          maxime quis, quisquam culpa explicabo magnam quia similique?
          {/* cspell:enable */}
        </p>
        <p>{/* <NumberOfGames /> */}</p>
        <div className="m-14">
          <h2 className="text-xl text-center m-4">Play vs. Bots</h2>
          <div className="flex justify-center gap-8">
            <NavLink to={playURLConstructor(GameTypes.HumanVsComputer)}>
              <Button>
                <FontAwesomeIcon icon={faUser} className="text-base pr-1" />
                <FontAwesomeIcon icon={fa2} className="text-base" /> Player Game
              </Button>
            </NavLink>
            <NavLink
              to={playURLConstructor(GameTypes.HumanVsComputerComputerComputer)}
            >
              <Button>
                <FontAwesomeIcon icon={faUser} className="text-base pr-1" />
                <FontAwesomeIcon icon={fa4} className="text-base" /> Player Game
              </Button>
            </NavLink>
          </div>
        </div>
        <div className="m-14">
          <h2 className="text-xl text-center m-4">Learn Gin Rummy</h2>
          <div className="flex justify-center gap-8">
            <NavLink to="/learn">
              <Button>Learn</Button>
            </NavLink>
          </div>
        </div>
      </div>
    </Page>
  );
};
