import { ReactNode, FC } from 'react';
import { PlayingCard } from '../../components/card';
import { Page } from '../../components/page';

interface LearnSectionProps {
  title: string;
  children: ReactNode;
}

const LearnSection: FC<LearnSectionProps> = ({ title, children }) => {
  return (
    <section className="mt-2">
      <h1 className="font-bold text-lg">{title}</h1>
      {children}
    </section>
  );
};

export const Learn = function () {
  return (
    <Page>
      <div className="text-left mx-4 justify-center">
        <div className="flex justify-center items-center mt-4">
          <h1 className="text-4xl font-bold inline mr-8">Learn Gin Rummy</h1>
          <div className="static h-48 p-4">
            <PlayingCard className="w-28 -rotate-12 -translate-x-5 absolute transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" />
            <PlayingCard className="w-28 -rotate-2 -translate-x-4 absolute transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" />
            <PlayingCard
              rank="8"
              suit="♠"
              className="w-28 rotate-12 -translate-x-3 absolute transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
            />
            <PlayingCard
              rank="A"
              suit="♥"
              className="w-28 rotate-[26deg] absolute transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
            />
            <PlayingCard
              rank="7"
              suit="♦"
              className="w-28 rotate-[34deg] translate-x-5 absolute transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
            />
          </div>
        </div>
        <div>
          <PlayingCard rank="A" suit="♠" className="w-28 inline" />
          <PlayingCard rank="2" suit="♠" className="w-28 inline" />
          <PlayingCard rank="3" suit="♠" className="w-28 inline" />
          <PlayingCard rank="4" suit="♠" className="w-28 inline" />
          <PlayingCard rank="5" suit="♠" className="w-28 inline" />
          <PlayingCard rank="6" suit="♠" className="w-28 inline" />
          <PlayingCard rank="7" suit="♠" className="w-28 inline" />
          <PlayingCard rank="8" suit="♠" className="w-28 inline" />
          <PlayingCard rank="9" suit="♠" className="w-28 inline" />
          <PlayingCard rank="10" suit="♠" className="w-28 inline" />
          <PlayingCard rank="J" suit="♠" className="w-28 inline" />
          <PlayingCard rank="Q" suit="♠" className="w-28 inline" />
          <PlayingCard rank="K" suit="♠" className="w-28 inline" />
        </div>
        <LearnSection title="Intro">
          <p>
            Gin Rummy is a fun game for two players or more players that
            balances skill and luck. Players attempt to transform their hands
            into as many sets and runs as possible to achieve a high score. Gin
            Rummy is an easy to learn, easy to play card game.
          </p>
          <p>
            Gin Rummy is played with a 52 card deck, the wild cards (jokers) are
            not used.
          </p>
        </LearnSection>
        <LearnSection title="Dealing">
          <p>
            In games with two players, 10 cards are dealt to each player. and
            place the remaining cards face down to form the draw pile. The top
            card of the draw pile is turned over to start the discard pile.
          </p>
        </LearnSection>
        <LearnSection title="Playing">
          <p>TBD //TODO add playing section</p>
        </LearnSection>
        <LearnSection title="Rank of Cards">
          <p>
            Rank of Cards The order of the cards, from highest to lowest, is:
            king (K), queen (Q), jack (J), ten, nine, eight, seven, six, five,
            four, three, deuce and ace. As for the value of the cards, the
            figure cards are worth 10 points and the other cards are worth the
            value indicated by their pips.
          </p>
        </LearnSection>
        <LearnSection title="Object of the Game">
          <p>
            Object of the Game Each player uses their hand to form combinations
            of three or more cards, to get more than the 100 points required to
            win the game before their opponent does so when played over several
            hands.
          </p>
        </LearnSection>
        <LearnSection title="">
          <p></p>
        </LearnSection>
        The Deal The deck is spread out on the table and each player takes a
        card. The player who draws the highest card chooses where to sit and
        deals out ten cards to each player, one by one, leaving the deck with
        the remaining cards in the center of the table. The top card of the
        stock deck is placed face up next to it to start the discard pile. In
        the following games, the player who wins the previous game becomes the
        dealer. The Play The player who did not deal the cards starts the game,
        with the option to pick up the upturned card next to the stock deck,
        meaning that one of their cards must be discarded. If the said card is
        of no interest, the player passes without discarding. The opponent may,
        in turn, take that card and discard another, and if they are not
        interested, they pass without discarding. Then the first player can now
        take the top card off the stock deck, discarding another. The game
        continues, with each player in turn being able to take the top card off
        the stock deck or the discard pile, then discarding a card, but which
        may not be the same card that they just picked up from the discard pile.
        The game consists of players grouping the 10 cards in their hand to make
        minimum combinations of three cards of the same rank or runs of the same
        suit. The ace can be combined with the deuce but not with the king (K).
        A player can fold when their hand contains only unmatched cards worth a
        total value of no more than 10 points, making a Knock. As soon as a
        player discards their last card, they show all of their cards,
        announcing the number of points that are left without combining. It is
        not compulsory to Knock, a player can prolong the game in order to
        improve their hand. The best hand is to make Gin, consisting of placing
        down the ten cards combined. In either case, when a player folds,
        exposing all of their cards, the opponent does the same, having the
        opportunity to get rid of those cards that were left unmatched and being
        able to combine cards with those exposed by the player who Knocked or
        announced Gin. When a player announces Gin they win the partial game,
        whereas if a player Knocks, either that player or the opposing player
        can win it. The player wins if the value of their unmatched cards is
        less than the value of the opponent’s unmatched cards and the opponent
        wins if the value of their unmatched cards is equal to or less than that
        of the one that Knocked. The cards of the opponent to the one who
        announced Gin or Knocked are valued after having discarded the cards
        that they have not combined and that link with combinations of the hand
        laid down by the one declared Gin or Knock. How to Keep Score A game
        ends when sufficient partial games have been played to allow one player
        to get 100 or more points. The player who makes Gin, scores 20 points
        plus the value of the opponent’s unmatched cards. If the player who
        Knocks wins the game, they score the difference in the value of their
        unmatched cards with those of their opponent, while if the opponent
        wins, they score 10 points plus the difference in the value of the
        unmatched cards between both players. If there is no difference, the 10
        point bonus remains. Once the game has finished, the players note down
        the following bonuses: 100 points for winning a game, 20 points for each
        partial game won and 100 points for winning all the rounds of a game
        without the opponent having won any.
      </div>
    </Page>
  );
};
