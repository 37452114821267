import { useAuth0 } from '@auth0/auth0-react';
import { Button } from './button';

export const SignupButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button
      kind="primary"
      onClickHandler={() =>
        loginWithRedirect({
          authorizationParams: {
            screen_hint: 'signup',
          },
        })
      }
    >
      Signup
    </Button>
  );
};
