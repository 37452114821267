import cx from 'classnames';
import { FC } from 'react';

export const ranks = [
  'A',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  'J',
  'Q',
  'K',
];
export type Rank = typeof ranks[number];

export const suits = ['♠', '♥', '♦', '♣'];
export type Suit = typeof suits[number];

interface CardCommonProps {
  cardSize: CardSize;
  className?: string;
}

interface CardFrontProps extends CardCommonProps {
  rank: Rank;
  suit: Suit;
}

interface CardBackProps extends CardCommonProps {}

interface PlayingCardProps extends Omit<CardCommonProps, 'cardSize'> {
  rank?: Rank;
  suit?: Suit;
}

interface CardSize {
  width: number;
  height: number;
  margin: number;
}

const CardBack: FC<CardBackProps> = function ({
  className,
  cardSize: { width, height, margin },
}) {
  return (
    <svg
      className={cx('drop-shadow-lg', className)}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="rounded stroke-cyan-900 stroke-1"
        width={width - margin * 2}
        height={height - margin * 2}
        x={margin}
        y={margin}
        rx="4"
        fill="url(#pattern)"
      />
      <pattern
        id="pattern"
        x="0"
        y="0"
        width="2"
        height="2"
        patternUnits="userSpaceOnUse"
      >
        <rect x="0" y="0" width="5" height="5" className="fill-cyan-800" />
        <circle cx="2.5" cy="2.5" r="2.5" className="fill-cyan-600" />
      </pattern>
    </svg>
  );
};

interface InnerLayoutProps {
  rank: Rank;
  suit: Suit;
  width: number;
  height: number;
  textColor: string;
}

type RankLayoutType = {
  [key: string]: number[];
};

const InnerLayout: FC<InnerLayoutProps> = function ({
  rank,
  suit,
  width,
  height,
  textColor,
}) {
  const rankLayout: RankLayoutType = {
    A: [1],
    2: [2],
    3: [3],
    4: [2, 2],
    5: [2, 1, 2],
    6: [3, 3],
    7: [3, 1, 3],
    8: [3, 2, 3],
    9: [4, 1, 4],
    10: [4, 2, 4],
    J: [1],
    Q: [1],
    K: [1],
  };

  const layout = rankLayout[rank];

  return (
    <g>
      {layout.map((count: number, i) => {
        const xOffset = width / (layout.length + 2);
        const x = xOffset * (i + 1) + xOffset / 2;

        return (
          <g key={i}>
            {Array.from({ length: count }).map((_, j) => {
              const yMargin = height * 0.1;
              let yOffset = (height - yMargin * 2) / count;
              let y = yOffset * j + yOffset / 2 + yMargin;
              const rotation = j > 1000 ? 'rotate-180' : 'rotate-0'; //FIXME

              if (rank === '7' && i === 1) {
                yOffset = (height - yMargin * 2) / 5;
                y = yOffset * 1 + yOffset / 2 + yMargin;
              }
              return (
                <text
                  key={j}
                  className={cx('no-select text-3xl', textColor, rotation)}
                  x={x}
                  y={y}
                  textAnchor="middle"
                  dominantBaseline="middle"
                >
                  {suit}
                </text>
              );
            })}
          </g>
        );
      })}
    </g>
  );
};

const CardFront: FC<CardFrontProps> = function ({
  rank,
  suit,
  className,
  cardSize: { width, height, margin },
}) {
  const textColor =
    suit === '♥' || suit === '♦' ? 'fill-red-500' : 'fill-black';

  return (
    <svg
      className={cx('drop-shadow-lg cursor-default', className, 'font-serif')}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <rect
          className="rounded stroke-black/10 fill-white stroke-1"
          width={width - margin * 2}
          height={height - margin * 2}
          x={margin}
          y={margin}
          rx="4"
        />
        <g>
          <text
            className={cx('no-select text-sm font-bold', textColor)}
            x="12%"
            y="6%"
            textAnchor="middle"
            dominantBaseline="hanging"
          >
            {rank}
          </text>
          <text
            className={cx('no-select text-sm font-bold', textColor)}
            x="12%"
            y="19%"
            textAnchor="middle"
            dominantBaseline="hanging"
          >
            {suit}
          </text>
        </g>
        <g transform={`translate(${width},${height}) rotate(180)`}>
          <text
            className={cx('no-select text-sm font-bold', textColor)}
            x="12%"
            y="6%"
            textAnchor="middle"
            dominantBaseline="hanging"
          >
            {rank}
          </text>
          <text
            className={cx('no-select text-sm font-bold', textColor)}
            x="12%"
            y="19%"
            textAnchor="middle"
            dominantBaseline="hanging"
          >
            {suit}
          </text>
        </g>
        <InnerLayout
          rank={rank}
          suit={suit}
          width={width}
          height={height}
          textColor={textColor}
        />
      </g>
    </svg>
  );
};

export const PlayingCard: FC<PlayingCardProps> = function ({
  rank,
  suit,
  className,
}) {
  const cardSize: CardSize = {
    width: 73,
    height: 102,
    margin: 1,
  };

  if (rank && suit) {
    return (
      <CardFront
        rank={rank}
        suit={suit}
        className={className}
        cardSize={cardSize}
      />
    );
  }

  return <CardBack className={className} cardSize={cardSize} />;
};
