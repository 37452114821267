import { useAuth0 } from '@auth0/auth0-react';
import { Button } from './button';

export const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <Button
      kind="secondary"
      onClickHandler={() =>
        logout({ logoutParams: { returnTo: window.location.origin } })
      }
    >
      Log Out
    </Button>
  );
};
