import { Auth0Provider } from '@auth0/auth0-react';
import { FC, ReactNode } from 'react';
import dotenv from 'dotenv';

dotenv.config();

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = function ({ children }) {
  const authDomain = process.env.AUTH_DOMAIN;
  if (!authDomain) {
    throw new Error(
      `Please define the AUTH_DOMAIN environment variable inside .env`
    );
  }

  const authClientId = process.env.AUTH_CLIENT_ID;
  if (!authClientId) {
    throw new Error(
      `Please define the AUTH_CLIENT_ID environment variable inside .env`
    );
  }

  return (
    <Auth0Provider
      domain={authDomain}
      clientId={authClientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage" // cSpell: disable-line
    >
      {children}
    </Auth0Provider>
  );
};
