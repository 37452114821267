import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiamond, faHeart } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import { FC } from 'react';

interface LogoProps {
  logoSize?: 'text-2xl';
}

export const Logo: FC<LogoProps> = function ({ logoSize = 'text-2xl' }) {
  return (
    <div className="w-8 mx-2 inline-block align-middle">
      <div className="w-8 relative inline fa-layers fa-fw center">
        <FontAwesomeIcon
          icon={faDiamond}
          className={cx('text-cyan-700', logoSize)}
        />
        <FontAwesomeIcon
          icon={faHeart}
          className={cx('text-cyan-500', logoSize)}
          transform="left-6"
        />
      </div>
    </div>
  );
};
