import { Link } from 'react-router-dom';
import { Logo } from '../logo';
import { LoginButton } from '../login-button';
import { useAuth0 } from '@auth0/auth0-react';
import { PlayMenu } from './components/play-menu';
import { ProfileMenu } from './components/profile-menu';

export const Header = function () {
  const { user, isAuthenticated } = useAuth0();

  return (
    <div className="relative bg-white">
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        <div className="flex items-center justify-between border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <Logo />
              <h1 className="inline ml-2 align-middle text-xl">
                Play Gin Rummy
              </h1>
            </Link>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              aria-expanded="false"
            >
              <span className="sr-only">Open menu</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </button>
          </div>
          <nav className="hidden space-x-5 md:flex">
            <PlayMenu />
            <Link
              to="/learn"
              className="text-base px-4 py-2 font-medium text-gray-500 hover:text-gray-900"
            >
              Learn
            </Link>
            <Link
              to="/about"
              className="text-base px-4 py-2 font-medium text-gray-500 hover:text-gray-900"
            >
              About
            </Link>
          </nav>
          <ProfileMenu user={user} isAuthenticated={isAuthenticated} />
          {!isAuthenticated && <LoginButton />}
        </div>
      </div>
    </div>
  );
};
