import { FC, ReactNode } from 'react';
import cx from 'classnames';

interface ButtonProps {
  children: ReactNode;
  kind?: 'primary' | 'secondary' | 'tertiary' | 'danger';
  className?: string;
  isDisabled?: boolean;
  type?: 'submit' | 'button';
  onClickHandler?: () => void;
}

export const Button: FC<ButtonProps> = function ({
  children,
  className,
  kind = 'primary',
  isDisabled = false,
  type = 'button',
  onClickHandler,
}) {
  return (
    <button
      disabled={isDisabled}
      type={type}
      className={cx('px-4 py-2 rounded-md border-2 font-semibold', className, {
        'bg-cyan-500 text-white border-cyan-500 hover:bg-cyan-600 hover:border-cyan-600':
          kind === 'primary',
        'bg-white text-cyan-500 border-cyan-500 hover:bg-cyan-100 hover:text-cyan-600 hover:border-cyan-600':
          kind === 'secondary',
        'bg-gray-800 text-white border-gray-800 hover:bg-gray-600 hover:border-gray-600':
          kind === 'tertiary',
        'bg-red-500 text-white border-red-500 hover:bg-red-600 hover:border-red-600':
          kind === 'danger',
      })}
      onClick={onClickHandler}
    >
      {children}
    </button>
  );
};
