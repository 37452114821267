import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Header } from './components/header/header';
import { Footer } from './components/footer';
import { Home } from './pages/home';
import { Play } from './pages/play';
import { Learn } from './pages/learn';
import { Account } from './pages/account';
import { createSocketConnection } from './socket';
import type { Socket } from 'socket.io-client';
import { AuthProvider } from './components/auth-provider';

const socketConnection: Socket = createSocketConnection();

const App = () => {
  return (
    <div className="App">
      <StrictMode>
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/play"
              element={<Play socketConnection={socketConnection} />}
            />
            <Route path="/learn" element={<Learn />} />
            <Route path="/about" element={<Learn />} />
            <Route path="/account" element={<Account />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </StrictMode>
    </div>
  );
};

const rootElement = document.getElementById('root');

if (rootElement) {
  createRoot(rootElement).render(
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}
