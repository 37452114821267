import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

interface NavLinkProps {
  to: string;
  className?: string;
  children: ReactNode;
}

export const NavLink = function (props: NavLinkProps) {
  const { children, to, className } = props;
  return (
    <Link to={to} className={cx('text-cyan-500 hover:underline', className)}>
      {children}
    </Link>
  );
};
